import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControlDirective} from './form-control.directive';
import {FormErrorsModule} from './form-errors/form-errors.module';
import {MediaModule} from './media/media.module';
import {NotEmptyValidator} from './not-empty.validator';

@NgModule({
  declarations: [
    FormControlDirective,
    NotEmptyValidator,
  ],
  exports: [
    FormControlDirective,
    FormErrorsModule,
    MediaModule,
    NotEmptyValidator,
  ],
  imports: [
    CommonModule,
    FormErrorsModule,
    MediaModule,
  ]
})
export class FormModule {
}
