import {GameDetails} from '../game';
import {FieldPolicy} from '@apollo/client/cache';
import {Reference} from '@apollo/client/utilities';

export type Rounds = GameDetails['rounds'];
export type Round = Rounds[number];
export type RoundQuestions = Round['questions'];
export type RoundQuestion = RoundQuestions[number];

export const roundCacheFieldPolicy: FieldPolicy<Reference> = {
  read: (_, {args, toReference}) => toReference({
    __typename: 'Round',
    id: args!['id'],
  })
};
