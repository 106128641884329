import * as Types from '../api.graphql.g';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings: { __typename?: 'Settings', rules?: string | null, rulesShort?: string | null, gameLaunch?: string | null, tooltips: Array<{ __typename?: 'Tooltip', code: string, value: string }> } };

export const SettingsDocument = gql`
    query settings {
  settings {
    tooltips {
      code
      value
    }
    rules
    rulesShort
    gameLaunch
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SettingsGQL extends Apollo.Query<SettingsQuery, SettingsQueryVariables> {
    override document = SettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }