import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, firstValueFrom, map, of} from 'rxjs';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {UserResetAction} from '../user/user.actions';

interface AuthResponse {
  accessToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  accessToken?: string | null;

  constructor(private router: Router,
              private store: Store,
              private http: HttpClient) {
  }

  get isAuthenticated() {
    return !!this.accessToken;
  }

  async initToken() {
    this.accessToken = await firstValueFrom(this.getToken().pipe(
      catchError(() => of(null))
    ));
  }

  async refreshToken() {
    this.accessToken = await firstValueFrom(this.getToken());
  }

  getToken() {
    return this.http.jsonp<AuthResponse>(environment.authUrl, 'callback').pipe(
      map(result => result?.accessToken),
    );
  }

  logout() {
    this.accessToken = null;
    this.store.dispatch(new UserResetAction());
  }

  redirectToLogin() {
    this.router.navigate(['login']);
  }
}
