<div class="side">
  <a href="#" mat-mini-fab color="none" routerLink="../" *ngIf="backAction">
    <mat-icon>arrow_back</mat-icon>
  </a>
</div>
<div class="center">
  <h2>
    <ng-content></ng-content>
  </h2>
</div>
<div class="side"></div>
