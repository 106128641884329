import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe as cp} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ContentComponent} from './content/content.component';
import {NavComponent} from './nav/nav.component';
import {MatButtonModule} from '@angular/material/button';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {HtmlPipe} from './html.pipe';
import {MatIconModule} from '@angular/material/icon';
import {VisibilityActionComponent} from './visibility-action/visibility-action.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TooltipComponent} from './tooltip/tooltip.component';
import {AudioButtonComponent} from './audio-button/audio-button.component';
import {CurrencyPipe} from './currency.pipe';
import {UnsavedDialogComponent} from './unsaved-dialog/unsaved-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    HeaderComponent,
    ContentComponent,
    NavComponent,
    HtmlPipe,
    VisibilityActionComponent,
    TooltipComponent,
    AudioButtonComponent,
    CurrencyPipe,
    UnsavedDialogComponent,
  ],
  exports: [
    HeaderComponent,
    ContentComponent,
    NavComponent,
    HtmlPipe,
    VisibilityActionComponent,
    TooltipComponent,
    AudioButtonComponent,
    CurrencyPipe,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  providers: [cp],
})
export class UiModule {
}
