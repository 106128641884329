import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientJsonpModule} from '@angular/common/http';
import {AuthService} from './auth.service';
import {LoginComponent} from './login/login.component';
import {RouterModule} from '@angular/router';
import {UiModule} from '../ui/ui.module';
import {FormModule} from '../form/form.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AppResolver} from '../app.resolver';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    HttpClientJsonpModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginComponent,
        resolve: {
          app: AppResolver,
        },
      }
    ]),
    UiModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService],
      useFactory: (auth: AuthService) => () => auth.initToken(),
    }
  ]
})
export class AuthModule {
}
