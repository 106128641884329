declare global {
  interface Window {
    title: string;
    apiUrl: string;
    authUrl: string;
    sentryUrl: string;
    sentryEnv: string;
    gtmId: string;
  }
}

export const environment = {
  production: true,
  title: window.title,
  apiUrl: window.apiUrl,
  authUrl: window.authUrl,
  sentryUrl: window.sentryUrl,
  sentryEnv: window.sentryEnv,
  gtmId: window.gtmId,
};
