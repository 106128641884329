import {FieldPolicy} from '@apollo/client/cache';
import {Reference} from '@apollo/client/utilities';
import {QuestionQuery} from './question.graphql.g';

export type Question = QuestionQuery['question'];

export const questionCacheFieldPolicy: FieldPolicy<Reference> = {
  read: (_, {args, toReference}) => toReference({
    __typename: 'Question',
    id: args!['id'],
  })
};
