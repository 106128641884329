import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GraphQLModule} from './graphql.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {NgxsModule} from '@ngxs/store';
import {FormModule} from './form/form.module';
import {AuthModule} from './auth/auth.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MAT_ICON_DEFAULT_OPTIONS, MatIconDefaultOptions, MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {UiModule} from './ui/ui.module';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {UserModule} from './user/user.module';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import * as Sentry from '@sentry/browser';
import * as SentryTracing from '@sentry/tracing';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {createErrorHandler, TraceService} from '@sentry/angular-ivy';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AppState} from './app.state';

registerLocaleData(localeRu);

if (environment.sentryUrl) {
  Sentry.init({
    dsn: environment.sentryUrl,
    environment: environment.sentryEnv,
    normalizeDepth: 10,
    integrations: [
      new SentryTracing.Integrations.BrowserTracing({traceFetch: false}),
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        xhr: false,
        fetch: false,
      }),
    ],
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([
      AppState,
    ]),
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
    MatSidenavModule,
    AuthModule,
    UserModule,
    FormModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    UiModule,

    AppRoutingModule,
  ],
  providers: [
    {
      provide: 'googleTagManagerMode',
      useValue: 'silent'
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {
      },
      deps: [TraceService],
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'app-dialog'
      }
    },
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: {
        fontSet: 'material-symbols-outlined',
      } as MatIconDefaultOptions,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
