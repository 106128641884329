import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediaFieldComponent} from './media-field/media-field.component';
import {MediaPreviewComponent} from './media-preview/media-preview.component';
import {MediaThumbnailComponent} from './media-thumbnail/media-thumbnail.component';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {SwiperModule} from 'swiper/angular';
import {MatIconModule} from '@angular/material/icon';
import {FileUploadModule} from './file-upload/file-upload.module';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ImageFieldComponent} from './image-field/image-field.component';


@NgModule({
  declarations: [
    ImageFieldComponent,
    MediaFieldComponent,
    MediaPreviewComponent,
    MediaThumbnailComponent,
  ],
  exports: [
    ImageFieldComponent,
    MediaFieldComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    CdkDropList,
    CdkDrag,
    SwiperModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
  ]
})
export class MediaModule {
}
