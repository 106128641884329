import {Component, ViewChild} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {FormControlDirective} from '../../form/form-control.directive';
import {LoginGQL} from './login.graphql.g';
import {catchValidationError, ValidationError} from '../../form/validation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @ViewChild(FormControlDirective)
  form?: FormControlDirective;

  email = '';
  loading = false;
  submitted = false;

  constructor(private loginGQL: LoginGQL) {
  }

  async submit() {
    this.loading = true;
    try {
      await firstValueFrom(this.loginGQL.mutate({email: this.email}).pipe(
        catchValidationError(),
      ));
      this.submitted = true;
    } catch (error) {
      if (error instanceof ValidationError) {
        this.form?.setBackendErrors(error);
        return;
      }
      this.form?.setGlobalError('Произошла ошибка, попробуйте еще раз или свяжитесь с нами');
      throw error;
    } finally {
      this.loading = false;
    }
  }
}
