export class UserLoadAction {
  public static readonly type = '[User] Load';
}

export class UserResetAction {
  public static readonly type = '[User] Reset';
}

export class UserUpdateBalanceAction {
  public static readonly type = '[User] Update Balance';
}
