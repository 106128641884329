import {SettingsQuery} from './app.graphql.g';

export type Settings = SettingsQuery['settings'];
export type Tooltips = Settings['tooltips'];

export class TooltipsCollection {
  constructor(private data: Tooltips) {
  }

  get(code: string): string {
    return this.data.find(item => item.code === code)?.value || '';
  }
}
