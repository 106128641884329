import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, from, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {extractFiles} from 'extract-files';
import {environment} from '../environments/environment';
import {NormalizedCacheObject} from '@apollo/client/cache/inmemory/types';
import introspectionResult from '../api.graphql.g';
import {gameCacheFieldPolicy} from './games/game/game';
import {roundCacheFieldPolicy} from './games/game/round/round';
import {questionCacheFieldPolicy} from './games/game/question/question';
import {AuthLink} from './auth/auth.link';
import {SentryLink} from 'apollo-link-sentry';

export function createApollo(httpLink: HttpLink, authLink: AuthLink): ApolloClientOptions<NormalizedCacheObject> {
  const sentryLink = new SentryLink({
    uri: environment.apiUrl,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: true,
      includeError: true,
    }
  });

  return {
    link: from([
      sentryLink,
      authLink.create(),
      httpLink.create({
        uri: environment.apiUrl,
        extractFiles,
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            game: gameCacheFieldPolicy,
            round: roundCacheFieldPolicy,
            question: questionCacheFieldPolicy,
          },
        },
      },
    }),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthLink],
    },
  ],
})
export class GraphQLModule {
}

export enum ErrorCategory {
  Validation = 'validation',
  Forbidden = 'forbidden',
  Authorization = 'authorization',
}
