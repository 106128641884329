import {Directive, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {delay, filter, first, startWith, switchMap} from 'rxjs/operators';
import {Observable, tap} from 'rxjs';
import {ValidationError} from './validation';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form',
  exportAs: 'formControl',
})
export class FormControlDirective {

  @Output() submitValid: Observable<void>;
  @Output() submitInvalid: Observable<void>;

  constructor(public instance: NgForm) {
    const formStatus$ = this.instance.ngSubmit
      .pipe(
        switchMap(() => this.instance.statusChanges!.pipe(
          startWith(this.instance.status),
          filter(status => status !== 'PENDING'),
          first(),
        )),
        delay(0),

        // Scroll to invalid field
        tap(status => {
          if (status !== 'VALID') {
            const invalid = Object.entries(this.instance.controls)
              .filter(([, field]) => field.invalid)
              .map(([key]) => key)
              .pop();

            if (invalid) {
              document.querySelector('[name="' + invalid + '"]')?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
          }
        })
      );

    this.submitValid = formStatus$.pipe(
      filter(status => status === 'VALID'),
    );

    this.submitInvalid = formStatus$.pipe(
      filter(status => status !== 'VALID'),
    );
  }

  setBackendErrors(error: ValidationError, fieldsMap: { [key: string]: string } = {}) {
    if (error.errors) {
      Object.entries(error.errors).forEach(([field, messages]) => {
        const name = fieldsMap[field] || field;
        const control = this.instance.controls[name] || this.instance.control;
        const message = messages.join(', ');
        control.setErrors({
          backend: {message}
        });
      });
    } else {
      this.setGlobalError(error.message);
    }
  }

  setGlobalError(message: string) {
    this.instance.control.setErrors({
      global: {message},
    });
  }
}
