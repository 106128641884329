import {setContext} from '@apollo/client/link/context';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {from, fromPromise} from '@apollo/client/core';
import {onError} from '@apollo/client/link/error';
import {ErrorCategory} from '../graphql.module';

@Injectable({
  providedIn: 'root'
})
export class AuthLink {

  refresh?: Promise<void>;

  constructor(private authService: AuthService) {
  }

  create() {
    const tokenContextLink = setContext((_, context) => {
      const accessToken = this.authService.accessToken;
      if (accessToken && !context['noAuth']) {
        const headers = context['headers'] || {};
        return {
          headers: {
            ...headers,
            Authorization: 'Bearer ' + accessToken,
          },
        };
      }
      return {};
    });

    const refreshLink = onError(({operation, graphQLErrors, forward}) => {
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          if (error.extensions && error.extensions['category'] === ErrorCategory.Authorization) {
            if (!this.refresh) {
              this.refresh = this.authService.refreshToken()
                .catch(error => {
                  this.authService.logout();
                  this.authService.redirectToLogin();
                  throw error;
                })
                .finally(() => {
                  this.refresh = undefined;
                });
            }

            return fromPromise(this.refresh)
              .flatMap(() => forward(operation));
          }
        }
      }
    })

    return from([tokenContextLink, refreshLink]);
  }
}
