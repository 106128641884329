import {Component, inject, Input} from '@angular/core';
import {AppState} from '../../app.state';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  @Input() code!: string;

  tooltips = inject(Store).selectSnapshot(AppState.tooltips);
}
