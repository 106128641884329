import {GameDetailsQuery} from './game.graphql.g';
import {FieldPolicy} from '@apollo/client/cache';
import {Reference} from '@apollo/client/utilities';

export type GameDetails = GameDetailsQuery['game'];

export const gameCacheFieldPolicy: FieldPolicy<Reference> = {
  read: (_, {args, toReference}) => toReference({
    __typename: 'Game',
    id: args!['id'],
  })
};
