import {Injectable, NgModule} from '@angular/core';
import {RouterModule, RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {UserResolver} from './user/user.resolver';
import {AuthGuard} from './auth/auth.guard';
import {NotFoundComponent} from './errors/not-found/not-found.component';
import {ErrorsModule} from './errors/errors.module';
import {AppResolver} from './app.resolver';

@Injectable({providedIn: 'root'})
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(state: RouterStateSnapshot) {
    const title = this.buildTitle(state);
    this.title.setTitle((title ? title + ' – ' : '') + environment.title);
  }
}

@NgModule({
  imports: [
    ErrorsModule,
    RouterModule.forRoot([
      {
        path: '',
        resolve: {
          app: AppResolver,
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'games',
          },
          {
            path: '',
            resolve: {
              user: UserResolver,
            },
            canActivate: [AuthGuard],
            children: [
              {
                path: 'games',
                loadChildren: () => import('./games/games.module').then(m => m.GamesModule),
              },
              {
                path: 'account',
                loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
              }
            ]
          },
          {
            path: 'help',
            resolve: {
              user: UserResolver,
            },
            loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
          },
          {
            path: '**',
            component: NotFoundComponent,
          }
        ]
      }
    ], {
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy
    },
  ]
})
export class AppRoutingModule {
}
