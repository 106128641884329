import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AppLoadAction} from './app.actions';
import {SettingsGQL} from './app.graphql.g';
import {map, Subscription} from 'rxjs';
import {Settings, TooltipsCollection} from './app';

export interface AppStateModel {
  settings: Settings | null,
  loading: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    settings: null,
    loading: true,
  },
})
@Injectable()
export class AppState {

  private updates?: Subscription;

  constructor(private settingsGQL: SettingsGQL) {
  }

  @Selector()
  public static getState(state: AppStateModel) {
    return state;
  }

  @Selector()
  public static settings({settings}: AppStateModel) {
    return settings;
  }

  @Selector()
  public static tooltips({settings}: AppStateModel): TooltipsCollection {
    return new TooltipsCollection(settings?.tooltips || []);
  }

  @Selector()
  public static rulesText({settings}: AppStateModel): string | null {
    return settings?.rules || null;
  }

  @Selector()
  public static rulesShortText({settings}: AppStateModel): string | null {
    return settings?.rulesShort || null;
  }

  @Selector()
  public static gameLaunchText({settings}: AppStateModel): string | null {
    return settings?.gameLaunch || null;
  }

  @Action(AppLoadAction)
  public async load(ctx: StateContext<AppStateModel>) {
    const query = this.settingsGQL.watch({}, {
      fetchPolicy: 'cache-and-network',
      context: {
        noAuth: true,
      },
    });

    this.updates?.unsubscribe();
    this.updates = query.valueChanges
      .pipe(map(result => result.data.settings))
      .subscribe(settings => ctx.patchState({
        settings,
        loading: false,
      }));

    return await query.result();
  }
}
