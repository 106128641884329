import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {UserLoadAction} from './user.actions';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver {

  constructor(private store: Store,
              private authService: AuthService) {
  }

  resolve() {
    if (this.authService.isAuthenticated) {
      return this.store.dispatch(new UserLoadAction());
    }
  }
}
