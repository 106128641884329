import * as Types from '../../../api.graphql.g';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: any | null };

export const LoginDocument = gql`
    mutation login($email: String!) {
  login(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }