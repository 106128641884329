import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormErrorsComponent} from './form-errors.component';
import {BackendErrorMessagesPipe} from './backend-error-messages.pipe';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [
    FormErrorsComponent,
    BackendErrorMessagesPipe,
  ],
  exports: [
    FormErrorsComponent,
    BackendErrorMessagesPipe,
  ],
  imports: [
    CommonModule,
    MatInputModule,
  ]
})
export class FormErrorsModule {
}
