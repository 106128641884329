import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {UserLoadAction, UserResetAction, UserUpdateBalanceAction} from './user.actions';
import {UserBalanceGQL, UserGQL} from './user.graphql.g';
import {firstValueFrom, map, Subscription} from 'rxjs';
import {User} from './user';

export interface UserStateModel {
  data: User | null,
  loading: boolean;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    data: null,
    loading: true,
  },
})
@Injectable()
export class UserState {

  private updates?: Subscription;

  constructor(private userGQL: UserGQL,
              private userBalanceGQL: UserBalanceGQL) {
  }

  @Selector()
  public static getState(state: UserStateModel) {
    return state;
  }

  @Selector()
  public static data({data}: UserStateModel) {
    return data;
  }

  @Selector()
  public static hasBalance({data}: UserStateModel) {
    return !!data?.balance && data.balance > 0;
  }

  @Action(UserLoadAction)
  public async load(ctx: StateContext<UserStateModel>) {
    const query = this.userGQL.watch({}, {fetchPolicy: 'cache-and-network'});

    this.updates?.unsubscribe();
    this.updates = query.valueChanges
      .pipe(map(result => result.data.user))
      .subscribe(user => ctx.patchState({
        data: user,
        loading: false,
      }));

    return await query.result();
  }

  @Action(UserResetAction)
  public async reset(ctx: StateContext<UserStateModel>) {
    ctx.patchState({
      data: null,
    })
  }

  @Action(UserUpdateBalanceAction)
  public async updateBalance() {
    return await firstValueFrom(this.userBalanceGQL.fetch());
  }
}
