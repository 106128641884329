import * as Types from '../../api.graphql.g';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, name: string, email: string, balance: number } };

export type UserBalanceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserBalanceQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, balance: number } };

export const UserDocument = gql`
    query user {
  user {
    id
    name
    email
    balance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    override document = UserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserBalanceDocument = gql`
    query userBalance {
  user {
    id
    balance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserBalanceGQL extends Apollo.Query<UserBalanceQuery, UserBalanceQueryVariables> {
    override document = UserBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }