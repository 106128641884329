import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileUploadComponent} from './file-upload.component';
import {FileInputAccessorModule} from 'file-input-accessor';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    FileInputAccessorModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatInputModule,
  ],
  exports: [
    FileUploadComponent,
  ]
})
export class FileUploadModule {
}
