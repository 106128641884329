import {Component, Inject, ViewChild} from '@angular/core';
import {fromEvent, map, Observable, take} from 'rxjs';
import {environment} from '../environments/environment';
import {User} from './user/user';
import {Select} from '@ngxs/store';
import {UserState} from './user/user.state';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MatSidenavContent} from '@angular/material/sidenav';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @Select(UserState.data)
  user$!: Observable<User>;

  @ViewChild(MatSidenavContent)
  sidenavContent!: MatSidenavContent;

  loading$ = this.router.events.pipe(
    filter(
      (e) =>
        e instanceof NavigationStart ||
        e instanceof NavigationEnd ||
        e instanceof NavigationCancel ||
        e instanceof NavigationError
    ),
    map((e) => e instanceof NavigationStart),
    take(2),
  );

  readonly showScroll$: Observable<boolean> = fromEvent(this.document, 'scroll').pipe(
    map(() => this.viewport.getScrollPosition()?.[1] > 100)
  );

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private viewport: ViewportScroller,
              private snackBar: MatSnackBar,
              private authService: AuthService,
              gtmService: GoogleTagManagerService) {
    if (environment.production) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          gtmService.pushTag({
            event: 'page',
            pageName: item.url
          });
        }
      });
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationError))
      .subscribe(() => {
        this.snackBar.open('Произошла ошибка. Попробуйте обновить страницу или повторите действие позже.', 'Закрыть');
      });
  }

  logout() {
    this.authService.logout();
    this.authService.redirectToLogin();
  }

  scrollTop() {
    return this.viewport.scrollToPosition([0, 0]);
  }
}
