import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AppLoadAction} from './app.actions';

@Injectable({
  providedIn: 'root'
})
export class AppResolver {

  constructor(private store: Store) {
  }

  resolve() {
    return this.store.dispatch(new AppLoadAction());
  }
}
