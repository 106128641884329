import {GraphQLErrorExtensions} from 'graphql/error/GraphQLError';
import {ErrorCategory} from '../graphql.module';
import {catchError, Observable} from 'rxjs';
import {ApolloError} from '@apollo/client/core';

export class ValidationError extends Error {
  override name = 'Validation error';

  constructor(public override message: string,
              public errors: ValidationErrors) {
    super();
  }
}

export interface ValidationErrors {
  [key: string]: string[];
}

interface ValidationExtensions extends GraphQLErrorExtensions {
  category: ErrorCategory.Validation;
  validation: Record<string, string[]>;
}

export const catchValidationError = () => <T>(source: Observable<T>): Observable<T> => source.pipe(
  catchError((error: Error) => {
    if (error instanceof ApolloError && error.graphQLErrors) {
      for (const e of error.graphQLErrors) {
        const extensions = e.extensions as ValidationExtensions;
        if (extensions.category === ErrorCategory.Validation) {
          throw new ValidationError(error.message, extensions.validation || undefined);
        }
      }
    }
    throw error;
  })
);
