<app-header [backAction]="false">Вход в систему</app-header>
<app-content [ngSwitch]="submitted">
  <mat-card *ngSwitchCase="false">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <form (submitValid)="submit()">
      <mat-card-header>
        Для авторизации укажите электронную почту. <br>
        Мы отправим ссылку для входа в письме.
      </mat-card-header>
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>Адрес электронной почты</mat-label>
          <input type="email" name="email" matInput [(ngModel)]="email" #emailModel="ngModel" email required>
          <app-tooltip matSuffix code="login_form.email"></app-tooltip>
          <mat-error *ngIf="emailModel.hasError('required')">Введите адрес электронной почты</mat-error>
          <mat-error *ngIf="emailModel.hasError('email')">В адресе электронной почты допущена ошибка</mat-error>
          <mat-error *ngIf="emailModel.hasError('backend')">{{emailModel.errors!['backend'].message}}</mat-error>
        </mat-form-field>
        <app-form-errors></app-form-errors>
        <div class="actions">
          <button mat-flat-button type="submit" color="primary" [disabled]="loading">Отправить</button>
        </div>
      </mat-card-content>
    </form>
  </mat-card>

  <mat-card *ngSwitchCase="true" class="success">
    <mat-card-content>
      <div>
        <img src="/assets/images/letter.svg" alt="">
      </div>
      <div>
        Письмо со ссылкой отправлено <br>
        на указанную почту
      </div>
    </mat-card-content>
  </mat-card>
</app-content>
