<mat-toolbar color="primary">
  <div class="wrapper">
    <span class="title">
      <img src="assets/images/logo.svg" alt="Квестикс">
      <span class="line"></span>
      <span class="text">Конструктор</span>
    </span>

    <app-nav *ngIf="user$ | async"></app-nav>

    <span class="spacer"></span>

    <a href="#" mat-icon-button routerLink="help">
      <mat-icon>help_outline</mat-icon>
    </a>

    <ng-container *ngIf="user$ | async as user">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="user">
          <div class="name">{{user.name}}</div>
          <div class="email">{{user.email}}</div>
        </div>
        <div class="balance">
          <div>Доступных запусков:</div>
          <div class="data">
            <div class="count">{{user.balance}}</div>
            <button mat-flat-button color="accent" routerLink="account">Пополнить</button>
          </div>
        </div>
        <a mat-menu-item routerLink="account/history">История операций</a>
        <button type="button" mat-menu-item (click)="logout()">Выход</button>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>

<router-outlet></router-outlet>

<div class="loading" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<button type="button" mat-fab color="regular" (click)="scrollTop()" class="scroll-top" *ngIf="showScroll$ | async">
  <mat-icon>arrow_upward</mat-icon>
</button>
